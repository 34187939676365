/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import './quickFlights.style.scss';

import DateStamp from 'components/generics/DateStamp/DateStamp';
import Button from 'components/generics/Button/Button';
import QuickFlightsTable from 'components/dashboard/QuickFlightsTable/QuickFlightsTable';

import { formatMinutesToReadableHour } from 'utils/func';
import { getByDate as getFlights } from 'services/API/Flight';

const QuickFlights = ({ date, title, returnValue }) => {
  const [data, setData] = useState([]);

  // TODO: loading data

  const formatDate = () => {
    const d = new Date(date);
    return `${d.getFullYear()}-${(d.getMonth() % 12) + 1}-${d.getDate()}`; // TODO : export as const func
    // return `2020-12-31`;
  };

  useEffect(() => {
    getFlights(formatDate(date), formatDate(date))
      .then(res => {
        setData(
          res.data
            .sort((a, b) => a.takeoffTimeUtc - b.takeoffTimeUtc)
            .filter(f => f.flightStatusId != 3)
            .map(e => [
              formatMinutesToReadableHour(e.takeoffTimeUtc + e.departureAirport.timeLagUtc * 60),
              e.departureAirport.icaoCode,
              e.arrivalAirport.icaoCode,
              e.mission.name,
              e.aircraft.aircraftType.name,
              e.passengerCapacity,
            ]),
        );
        returnValue(res.data.length);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <div className="QuickFlight">
      <div className="QuickFlightHeader">
        <DateStamp date={date} />
        <span>
          <div className="QuickFlightTitle">{title}</div>
          <div className="QuickFlightType">Departure</div>
        </span>
        <Button text="More details" defaultStyle="primary" onClick={() => console.log('todo redirect')} />
      </div>
      <QuickFlightsTable datas={data} />
    </div>
  );
};

export default QuickFlights;
