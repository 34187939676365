import Bar from 'components/generics/Charts/Bar';

const DelaysClassContainer = ({ data, loading }) =>
  !loading ? (
    <>
      <div className="highlight_value">
        <div>
          <span>{data?.find(v => v.class === 'InAdvance').counter}</span>
          <span>depart in advance</span>
        </div>
        <div>
          <span>{data?.find(v => v.class === 'OnTime').counter}</span>
          <span>depart on time</span>
        </div>
      </div>
      <div className="graphContainer">
        <Bar data={data} keys={['counter']} indexBy={'class'} />
      </div>
    </>
  ) : (
    <>loading</>
  );

export default DelaysClassContainer;
