import './AircraftCost.scss';
import { useEffect, useState } from 'react';
import { getAircraftCost } from '../../../../services/API/Stats';
import { prepareTable } from '../../../../utils/func';
import { AIRCRAFT_COST_STATS_HEADERS } from '../../../../utils/const';
import { Loader } from '../../../generics/Loader/Loader';
import Table from '../../../generics/Table/ReactTable';
import Bar from '../../../generics/Charts/Bar';
import RoleGuard from '../../../generics/RoleGuard/RoleGuard';
import { UserRole } from '../../../../utils/interface/user';

export const AircraftCost = () => {
  const [data, setData] = useState(null as any);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAircraftCost()
      .then(res => {
          // console.log(res.data);
          setData({
          datas: prepareTable(AIRCRAFT_COST_STATS_HEADERS, res.data),
          headers: AIRCRAFT_COST_STATS_HEADERS,
        });
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <RoleGuard allowedRoles={[UserRole.CPS_ADMIN]}>
      <div id="FlightTimesStats" className="statsContainer">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Table datas={data.datas} headers={data.headers} filters={false} selectable={false} onEdit={null} selectedRows={() => {}}></Table>
            <div className="statsSection" id="chart">
              <h3>Aircraft Cost</h3>
              <div className="graphContainer">
                <Bar
                  legends={['Planned', 'Burnt']}
                  data={data?.datas}
                  keys={['plannedAircraftCost', 'burntAircraftCost']}
                  indexBy={'reg'}
                  mode={'grouped'}
                  labels={['Planned Cost', 'Burnt Cost']}
                  colors={['#03A9F4', '#016593']}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </RoleGuard>
  );
};
