import { useState, useEffect } from 'react';

import Table from 'components/generics/Table/ReactTable';
import Button from 'components/generics/Button/Button';
import Modal from 'components/generics/Modal/Modal';
import FormUser from 'components/pages/parameters/Users/FormUser';
import ConfirmDelete from 'components/generics/ConfirmDelete/ConfirmDelete';
import useAuth from 'providers/Auth/useAuth';

import { get as getUser, bulkDelete as deleteUsers } from 'services/API/User';

import { prepareTable } from 'utils/func';
import { USER_HEADERS } from 'utils/const';

const Users = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formType, setFormtype] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [data, setData] = useState({ datas: [], headers: [] });
  const [editId, setEditId] = useState(null);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    getUser()
      .then(res => setData({ datas: prepareTable(USER_HEADERS, res.data), headers: USER_HEADERS }))
      .catch(e => console.error(e));
  };

  const openModalEdit = e => {
    setFormtype('edit');
    setEditId(e.id);
    setModalVisibility(true);
  };

  const openModalAdd = e => {
    setFormtype('add');
    setEditId(e.id);
    setModalVisibility(true);
  };

  const onSubmitForm = () => {
    setModalVisibility(false);
    getDatas();
  };

  const onDelete = () => {
    if (selectedUser.length > 0) {
      deleteUsers({ ids: selectedUser.map(v => +v.original.id) })
        .then(e => {
          getDatas();
          setConfirmDeletePopup(false);
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <div className="params crud" id="user">
      <div className="crud__header">
        <div>
          <h1>User</h1>
          <h2>{user?.datasource.replace('_', ' ')}</h2>
        </div>
        <Button
          text="Delete"
          disabled={selectedUser.length === 0}
          defaultStyle={`primary${selectedUser.length === 0 ? ' disabled' : ''}`}
          onClick={() => setConfirmDeletePopup(true)}
        />
        {/* <Button
          text='Export'
          disabled={selectedUser.length === 0}
          defaultStyle={`primary${selectedUser.length === 0 ? ' disabled' : ''}`}
          onClick={() => console.log('Export')}
        /> */}
        <Button text="Add user" defaultStyle="primary" onClick={openModalAdd} />
      </div>
      <Table selectedRows={d => setSelectedUser(d)} datas={data.datas} headers={data.headers} editable={true} onEdit={openModalEdit} name="usersFilters" />
      {modalVisibility && (
        <Modal onClose={() => setModalVisibility(false)}>
          <FormUser onSubmit={onSubmitForm} editId={editId} type={formType} />
        </Modal>
      )}
      {confirmDeletePopup && (
        <ConfirmDelete onClose={() => setConfirmDeletePopup(false)} list={selectedUser} onConfirm={onDelete} formatList={data => `Item : ${data.name}`} />
      )}
    </div>
  );
};

export default Users;
