import { useState } from 'react';

import Bar from 'components/generics/Charts/Bar';
import { Loader } from '../../../generics/Loader/Loader';

const TotalFuel = ({ data, loading }) => {
  const [plannedData, setPlannedData] = useState(true);

  return !loading ? (
    <>
      <div className="highlight_value">
        <div>
          <span>{data?.totalFuelAvailable.toLocaleString()}L</span>
          <span>Fuel planned</span>
        </div>
        <div>
          <span>{data?.totalFuelUsed.toLocaleString()}L</span>
          <span>Fuel burnt</span>
        </div>
        <div>
          <span>{Math.max(data?.totalFuelRemaining, 0).toLocaleString()}L</span>
          <span>Fuel available</span>
        </div>
        <div>
          <span>{Math.ceil(data?.totalFuelRequested).toLocaleString() || 0}L</span>
          <span>Fuel requested</span>
        </div>
        <div>
          <label name="switchData">
            <input
              name="switchData"
              type="checkbox"
              onChange={() => {
                setPlannedData(!plannedData);
              }}
              checked={plannedData}
            />
            Unstack bars
          </label>
        </div>
      </div>
      <div className="graphContainer">
        <Bar
          data={data?.airports.map(d => (d.fuelRemaining < 0 ? { ...d, ...{ fuelRemaining: 0 } } : d))}
          keys={plannedData ? ['fuelAvailable', 'fuelUsed', 'requestedFuel'] : ['fuelUsed', 'fuelRemaining', 'requestedFuel']}
          mode={plannedData ? 'grouped' : 'stacked'}
          labels={plannedData ? ['Fuel Planned', 'Fuel Burnt', 'Fuel Requested'] : ['Fuel Burnt', 'Fuel Remaining', 'Fuel Requested']}
          indexBy={'icaoCode'}
          label={!plannedData}
          colors={['#03A9F4', '#eb5367', '#016593']}
        />
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default TotalFuel;
