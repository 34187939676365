import { ResponsiveBar } from '@nivo/bar';

const Bar = ({ data, keys, indexBy = 'id', valueScale = 'linear', colors = ['#03A9F4'], label = true, mode = 'stacked', labels = [], legends = [] }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 10, bottom: 90, left: 40, right: 40 }}
      padding={0.5}
      groupMode={mode}
      valueScale={{ type: valueScale }}
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#fff"
      enableLabel={label}
      tooltip={e => (
        <p className="overBarGraph">
          <span className="overLegendColor" style={{ backgroundColor: e.color }}></span>
          {labels && labels.length ? labels[keys.indexOf(e.id)] : e.data[indexBy]} - {e.data[e.id]}
        </p>
      )}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 100,
          translateY: 50,
          itemWidth: 100,
          itemHeight: 20,
          itemsSpacing: 2,
          symbolSize: 20,
          itemDirection: 'left-to-right',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
          data: legends.map((legend, index) => ({
            id: keys[index],
            label: legend,
            color: colors[index],
          })),
        },
      ]}
    />
  );
};

export default Bar;
